import React, { useState } from 'react';
import { createMarkUp } from '../../helpers';

import { ComponentStyles, Table, Ribbon, StyledIframe } from './styles.jsx';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';
import { ButtonComponent } from '../../V2/Button/Button';
import { PopUpWrapStyles } from '../../V2/StickyHeaderNavigation/styles';
import { ShowVideo } from '../../V2/Grid/GridItems/GridItemHelper';

interface Props {
  title?: string;
  ribbon?: string;
  lang?: string;
  subtitle?: string;
  rows?: object;
  prices?: object;
  goModel?: object;
  plusModel?: object;
  globalCopy?: object;
  sitemap?: object;
}

export const FeatureShow: React.FC<Props> = ({ data }) => {
  if (data) {
    return <div>check</div>;
  } else {
    return <div>x</div>;
  }
};

export const PlanComparisonTable = ({
  ribbon = '',
  title = '',
  subtitle,
  prices,
  rows,
  sitemap,
  goModel,
  plusModel,
  globalCopy,
  lang = 'en',
  ...props
}: Props) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([0]);

  const [openPopUp, setOpenPopUp] = useState(false);
  const [openPlusPopUp, setOpenPlusPopUp] = useState(false);

  const toggleRow = (index: number) => {
    setOpenIndexes(
      prevOpenIndexes =>
        prevOpenIndexes.includes(index)
          ? prevOpenIndexes.filter(i => i !== index) // Closes row if it is already open
          : [...prevOpenIndexes, index] // Open row if not already open
    );
  };

  return (
    <>
      {openPopUp && (
        <PopUpWrapStyles>
          <StyledIframe>
            <button
              className={'close'}
              onClick={() => {
                setOpenPopUp(false);
              }}
            >
              <img src={'/assets/icons/close.svg'} />
            </button>

            <ProductLogo Logo={'geomagic-designx-go'} />
            <h3>{goModel.title}</h3>
            <p dangerouslySetInnerHTML={createMarkUp(goModel.copy)}></p>
            {ShowVideo(goModel.video)}

            <ButtonComponent
              Link={sitemap['geomagic-designx-download'][lang]}
              Theme={'geomagic-designx'}
              Label={globalCopy.Buttons.GetFreeTrial}
            />
          </StyledIframe>
        </PopUpWrapStyles>
      )}
      {openPlusPopUp && (
        <PopUpWrapStyles>
          <StyledIframe>
            <button
              className={'close'}
              onClick={() => {
                setOpenPlusPopUp(false);
              }}
            >
              <img src={'/assets/icons/close.svg'} />
            </button>

            <ProductLogo Logo={'geomagic-designx-plus'} />
            <h3>{plusModel.title}</h3>
            <p dangerouslySetInnerHTML={createMarkUp(plusModel.copy)}></p>
            {ShowVideo(plusModel.video)}
            <ButtonComponent
              Link={sitemap['geomagic-designx-download'][lang]}
              Theme={'geomagic-designx'}
              Label={globalCopy.Buttons.GetFreeTrial}
            />
          </StyledIframe>
        </PopUpWrapStyles>
      )}
      <ComponentStyles className={'Component-PlanComparisonTable'} {...props}>
        <div className={'container'}>
          <h3>{subtitle}</h3>
          <h2 dangerouslySetInnerHTML={createMarkUp(title)}></h2>

          <Table>
            <div></div>

            <div className={'product-title'}>
              <ProductLogo Logo={'geomagic-designx-go-short'} />
              <ButtonComponent
                Theme={'geomagic-designx-outline'}
                Label={globalCopy.Buttons.DownloadTrial}
                Target={'_blank'}
                Link={sitemap['geomagic-designx-download'][lang]}
              />

              <Ribbon className={'ribbon'}>{ribbon}</Ribbon>
            </div>
            <div className={'product-title'}>
              <ProductLogo Logo={'geomagic-designx-plus-short'} />
              <ButtonComponent
                Theme={'geomagic-designx-outline'}
                Label={globalCopy.Buttons.DownloadTrial}
                Target={'_blank'}
                Link={sitemap['geomagic-designx-download'][lang]}
              />
              <Ribbon className={'ribbon'}>{ribbon}</Ribbon>
            </div>
            <div className={'product-title'}>
              <ProductLogo Logo={'geomagic-designx-pro-short'} />
              <ButtonComponent
                Theme={'geomagic-designx-outline'}
                Label={globalCopy.Buttons.DownloadTrial}
                Target={'_blank'}
                Link={sitemap['geomagic-designx-download'][lang]}
              />
            </div>
            {rows.map((row, index) => (
              <>
                <div
                  className={openIndexes.includes(index) ? 'active header' : 'header'}
                  onClick={() => toggleRow(index)}
                >
                  {row.title}
                  <img src={'/assets/icons/chevron-down.svg'} />
                </div>
                <div className={'empty'}></div>
                <div className={'empty'}></div>
                <div className={'empty'}></div>

                {openIndexes.includes(index) && <div className={'divider'}></div>}

                {openIndexes.includes(index) &&
                  row.items.map((item, key) => (
                    <React.Fragment key={key}>
                      <div className={'description'} dangerouslySetInnerHTML={createMarkUp(item.title)}></div>
                      <div className={`item-status row-${key}`}>
                        {item.go ? (
                          <img src={'/assets/icons/check-primary.svg'} />
                        ) : (
                          <img src={'/assets/icons/close-faded.svg'} />
                        )}
                      </div>
                      <div className={`item-status row-${key}`}>
                        {item.plus ? (
                          <img src={'/assets/icons/check-primary.svg'} />
                        ) : (
                          <img src={'/assets/icons/close-faded.svg'} />
                        )}
                      </div>
                      <div className={`item-status row-${key}`}>
                        {item.pro ? (
                          <img src={'/assets/icons/check-primary.svg'} />
                        ) : (
                          <img src={'/assets/icons/close-faded.svg'} />
                        )}
                      </div>
                    </React.Fragment>
                  ))}

                <div className={'divider'}></div>
              </>
            ))}
            <div></div>
            <div className={'end'}></div>
            <div className={'end'}></div>
            <div className={'end'}></div>
            <div></div>
            <div className={'button-wrapper'}>
              <ButtonComponent
                ClickFunction={() => {
                  setOpenPopUp(true);
                }}
                Label={globalCopy.Buttons.WatchPreview}
                Theme={'geomagic-designx'}
              />
            </div>
            <div className={'button-wrapper'}>
              <ButtonComponent
                ClickFunction={() => {
                  setOpenPlusPopUp(true);
                }}
                Label={globalCopy.Buttons.WatchPreview}
                Theme={'geomagic-designx'}
              />
            </div>
            <div className={'button-wrapper'}>
              <ButtonComponent
                Label={globalCopy.Buttons.DownloadFreeTrial}
                Target={'_blank'}
                Link={sitemap['geomagic-designx-download'][lang]}
                Theme={'geomagic-designx'}
              />
            </div>
          </Table>
        </div>
      </ComponentStyles>
    </>
  );
};
